import Api from '@/services/Index';

// Reservation
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations/' + id);
}
const updateReservationStatus = async (id,data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/housing/reservations/'+id + '/status',data );
}

const update = async (id,data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/housing/reservations/'+id ,data );
}


export default {
    getAll,
    get,
    updateReservationStatus,
    update
}
